<template>
    <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex align-items-start">
        This is free Self Test provided bt <span class="text-primary ms-1 me-1"> vipawaworks </span>, no one will see your results.
        </li>
        <li class="list-group-item d-flex align-items-start">
        This Test aims to improve your knowledge and understanding so that you can be a compitent candidate
        </li>
        <li class="list-group-item d-flex align-items-start">
        This Test Has <span class="text-primary ms-1 me-1"> {{ test.test_questions_count }} </span> questions
        </li>
        <li class="list-group-item d-flex align-items-start">
        Attempt <span class="text-primary ms-1 me-1">all</span> questions
        </li>
        <li class="list-group-item d-flex align-items-start">
        Each Question carries <span class="text-primary ms-1 me-1">1</span> mark
        </li>
        <li class="list-group-item d-flex align-items-start">
        <span class="text-primary me-1">No </span> mark will be deducted by skipping a question
        </li>
        <li class="list-group-item d-flex align-items-start">
        You Have to Score Atleast <span class="text-primary ms-1 me-1">{{ test.test_questions_count /2 }}</span> of <span class="text-primary ms-1 me-1">{{ test.test_questions_count}}</span> marks to pass the test
        </li>
        <li class="list-group-item d-flex align-items-start">
        Refreshing the browser will make a test invalid
        </li>
        <li class="list-group-item d-flex align-items-start">
        Time Alloted <span class="text-primary ms-1 me-1"> {{ new Date(test.duration  * 1000).toISOString().substr(14, 5)}} </span> minutes
        </li>
        <li class="list-group-item d-flex align-items-start">
        Increase your knowledge in our <a href="http://training.vipawaworks.com" class="text-primary ms-1 me-1" target="_blank"> Training website</a>
        </li>
    </ol>
</template>

<script>
export default {
    props:['test']

}
</script>

<style>

</style>